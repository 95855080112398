* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f3f3f3;
  -webkit-font-smooting: antialiased; 
}

body, input, textarea, button, p, pre {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h2, h3, h4, h5, h6, strong {
  font-weight: 600;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

button {
  cursor: pointer;
}

[disbled] {
  opacity: 0.6;
  cursor: not-allowed;
}